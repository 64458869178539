.footer {
	color: $white;
	background: rgb(3,100,179);
  	background: linear-gradient(180deg, rgba(3,100,179,1) 0%, rgba(14,107,182,1) 20%, rgba(41,171,229,1) 100%);
	text-align: center;

	& > .container {
		padding-top: rem(90);
		padding-bottom: rem(90);

		@include query-to(1240) {
			padding-top: rem(60);
			padding-bottom: rem(60);
		}

		h2 {
			color: $white;
			margin-bottom: rem(40);
		}
		.grid {
			max-width: 940px;
			width: 100%;
			margin: 0 auto;
			display: grid;
			grid-template-columns: repeat(3, 1fr);
			grid-row-gap: rem(40);
			grid-column-gap: rem(90);

			@include query-to(1040) {
				grid-column-gap: rem(40);
			}
			@include query-to(660) {
				grid-template-columns: repeat(1, 1fr);
			}

			.grid__item {
				.icon {
					margin-bottom: rem(30);
					height: 55px;
					display: flex;
    				align-items: flex-end;
    				justify-content: center;
				}
				p, a {
					line-height: 1.5;
				}
			}
		}
	}
	.copyright {
		padding: rem(15) 0 rem(40);
	    border-top: solid 1px rgba(226, 226, 226, .4);
	    font-size: rem(14);

		.container {
			display: flex;
			justify-content: space-between;

			p,a {
				line-height: 1.5;
			}

			@include query-to(700) {
				flex-direction: column;
			}
		}
	}    
}