/*==========================================================================*\
  # Config
\*==========================================================================*/

// Fonts
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url('../fonts/Open-Sans-Regular.eot'); /* IE9 */
  src: url('../fonts/Open-Sans-Regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('../fonts/Open-Sans-Regular.woff2') format('woff2'), /* Modern Browsers */
    url('../fonts/Open-Sans-Regular.woff') format('woff'), /* Modern Browsers */
    url('../fonts/Open-Sans-Regular.ttf') format('truetype'), /* Safari, Android, iOS */
    url('../fonts/Open-Sans-Regular.svg#OpenSans') format('svg'); /* Legacy iOS */
}
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url('../fonts/Open-Sans-600.eot'); /* IE9 */
  src: url('../fonts/Open-Sans-600.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('../fonts/Open-Sans-600.woff2') format('woff2'), /* Modern Browsers */
    url('../fonts/Open-Sans-600.woff') format('woff'), /* Modern Browsers */
    url('../fonts/Open-Sans-600.ttf') format('truetype'), /* Safari, Android, iOS */
    url('../fonts/Open-Sans-600.svg#OpenSans') format('svg'); /* Legacy iOS */
}
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url('../fonts/Open-Sans-700.eot'); /* IE9 */
  src: url('../fonts/Open-Sans-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('../fonts/Open-Sans-700.woff2') format('woff2'), /* Modern Browsers */
    url('../fonts/Open-Sans-700.woff') format('woff'), /* Modern Browsers */
    url('../fonts/Open-Sans-700.ttf') format('truetype'), /* Safari, Android, iOS */
    url('../fonts/Open-Sans-700.svg#OpenSans') format('svg'); /* Legacy iOS */
}


// Color palette – Primary colors
$white: #FFFFFF;
$black: #000000;
$blue-light: #29abe5;
$blue: #0e6bb6;
$blue-dark: #0364b3;

$red-form: #ffadad;
$red-form-dark: #8d3131;
$green-form: #46b450;
$green-form-light: #c1ffc7;

// Defaults
$font: 'Open Sans';