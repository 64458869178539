/*==========================================================================*\
  # Utility
\*==========================================================================*/

.padding {
  box-sizing: border-box;
  padding: 0 10% 0 10%;
}

.reverse {
  direction: rtl;
}

.relative {
  position: relative;
}

input:focus {
  outline: none!important;
}

.flex {
  display: flex;
}

//cookies bar style 

.cc-link {
  padding: 0!important;
  display: inline!important;
}