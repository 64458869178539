body {
  font-family: $font;
  font-size: 16px;
  font-weight: 300;
  line-height: 1.3;
  background-color: $white;
  color: $blue-dark;
  -webkit-font-smoothing: antialiased;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  a {
    text-decoration: none;
  }
}

p,
ul,
ol,
dl,
table,
blockquote,
pre,
figure {
  margin-bottom: 0;
}

ul ul,
ol ol,
ul ol,
ol ul {
  margin-bottom: 0;
}

span {
  display: inline-block;
}

img {
  max-width: 100%;
  height: auto;
}

blockquote {
  font-style: italic;
  p {
    margin: 0;
  }
}

picture {
  display: inline-block;
}

button {
  background-color: transparent;
}

a {
  display: inline-block;
  text-decoration: none;
  color: inherit;
  &:hover {
    text-decoration: underline;
    color: inherit;
  }
}

.hidden {
  display: none;
}

.overflow-hidden {
  overflow: hidden;
}