body.error404 {
    main {
        padding: 0;
    }
}

.error-page {
    .container {
        height: 100vh;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        text-align: center;

        @media (max-height: 750px) and (max-width: 1200px) {
            height: auto;
            padding: rem(120) rem(20) rem(80);
        }

        h1 {
            max-width: 500px;
            width: 100%;
            padding-bottom: rem(25);
        }

        p {
            padding-bottom: rem(20);
            max-width: 540px;
            width: 100%;
            margin: 0 auto;
        }
        
        .button {
            margin-top: rem(30);
        }
    }
}