h1,
.h1 {
    font-size: rem(40);
    line-height: 1.5;
    font-weight: 700;
    color: $blue;

    @include query-to(1240) {
        font-size: rem(30);
    }
}

h2,
.h2 {
    color: $blue;
    font-size: rem(25);
    line-height: 1.5;
    font-weight: 700;
}

h3,
.h3 {
    color: $blue;
    font-size: rem(20);
    line-height: 1.5;
    font-weight: 700;
}