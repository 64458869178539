.page-id-19 .text-page {
	background: url("../img/backgrounds/text-page-bg.jpg") center no-repeat;
}

.text-page {
	padding: rem(250) 0 rem(150);

	@include query-to(1240) {
		padding: rem(110) 0 rem(60);
	}

	.inner {
		max-width: 877px;
		width: 100%;
		margin: 0 auto;

		.text-page__logo {
			text-align: center;
			margin-bottom: rem(30);

			img {}
		}
		.content {
			h1 {
				text-align: center;
				margin-bottom: rem(50);
			}
			h2 {
				margin-bottom: rem(30);
			}
			h3 {
				margin-bottom: rem(20);
			}

			p {
				margin-bottom: rem(20);
				line-height: 1.5;
			}
			img {
				margin: rem(30) 0;
			}
			ul, ol {
				padding-left: rem(20);
				margin: rem(40) 0;
				line-height: 1.5;

				li {
					//font-size: rem(20);
					margin-bottom: rem(10);

					ul {
					    padding-left: rem(22);
    					margin: rem(30) 0;
						li {}
					}

					&:last-child {
						margin-bottom: 0;
					}
				}
			}
			& > :last-child {
		        margin-bottom: 0;
		  	}
		}
		.text-page__footer {
			margin-top: rem(50);
			text-align: center;
		}
	}

  	
}