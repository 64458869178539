/*==========================================================================*\
  # Form
\*==========================================================================*/

.wpcf7 {
    .form__header {
        text-align: center;
    }
    .form__inner {
        padding-bottom: rem(50);

        @include query-to(700) {
            padding-bottom: rem(30);
        }

        .wrapper {
            display: flex;
            justify-content: space-between;
            gap: 20px;
            //margin-bottom: rem(30);

            @include query-to(740) {
                flex-direction: column;
                //margin-bottom: rem(20);
            }
        }
        .left,
        .right {
            max-width: 480px;
            width: 100%;

            @include query-to(740) {
                max-width: 100%;
            }
            .item {
                margin-bottom: rem(30);

                @include query-to(740) {
                    margin-bottom: rem(25);
                }
            }
            & > :last-child {
                margin-bottom: 0;
            }
        }

        .radio {
            display: flex;
            margin: rem(30) 0;

            @include query-to(700) {
                flex-direction: column;
            }

            label {
                margin-bottom: 0;
                max-width: 400px;
                width: 100%;
                flex: 1 0 auto;
                line-height: 1.5;
            }

            .wpcf7-radio {
                display: flex;
                padding-left: rem(70);

                @include query-to(700) {
                    margin-top: rem(10);
                    padding-left: 0;
                }

                .wpcf7-list-item {
                    margin: 0;
                    width: 120px;

                    @include query-to(700) {
                        width: 90px;
                    }

                    label {
                        width: 100%;
                        max-width: 100%;

                        [type="radio"]:checked,
                        [type="radio"]:not(:checked) {
                            position: absolute;
                            left: -9999px;
                        }
                        [type="radio"]:checked + span,
                        [type="radio"]:not(:checked) + span
                        {
                            position: relative;
                            padding-left: 28px;
                            cursor: pointer;
                            line-height: 20px;
                            display: inline-block;
                            color: #666;
                        }
                        [type="radio"]:checked + span:before,
                        [type="radio"]:not(:checked) + span:before {
                            content: '';
                            position: absolute;
                            left: 0;
                            top: 0;
                            width: 20px;
                            height: 20px;
                            border: 1px solid $blue-dark;
                            border-radius: 100%;
                            background: #fff;
                        }
                        [type="radio"]:checked + span:after,
                        [type="radio"]:not(:checked) + span:after {
                            content: '';
                            width: 12px;
                            height: 12px;
                            background: $blue-dark;
                            position: absolute;
                            top: 4px;
                            left: 4px;
                            border-radius: 100%;
                            -webkit-transition: all 0.2s ease;
                            transition: all 0.2s ease;
                        }
                        [type="radio"]:not(:checked) + span:after {
                            opacity: 0;
                            -webkit-transform: scale(0);
                            transform: scale(0);
                        }
                        [type="radio"]:checked + span:after {
                            opacity: 1;
                            -webkit-transform: scale(1);
                            transform: scale(1);
                        }
                    }
                }
            }
        }

        .checkbox {
            margin-top: rem(50);

            @include query-to(700) {
                margin-top: rem(30);
            }

            span.wpcf7-list-item {
                display: inline-block;
                margin: 0;
                display: block;

                label {
                    position: relative;
                    cursor: pointer;
                    max-width: 400px;
                    width: 100%;
                    flex: 1 0 auto;

                    input[type=checkbox] {
                        /*position: relative;*/
                        position: absolute;
                        visibility: hidden;
                        width: 20px;
                        height: 20px;
                        top: 0;
                        left: 0;
                        & + span {
                            /* border: 3px solid red; */
                            &:before {
                                display: block;
                                position: absolute;
                                content: '';
                                border-radius: 0;
                                height: 20px;
                                width: 20px;
                                top: 0px;
                                left: 0px;
                                border: 1px solid $blue-dark;
                                border-radius: 5px;
                            }
                            &:after {
                                display: block;
                                position: absolute;
                                content: "\2713";
                                height: 20px;
                                width: 20px;
                                top: 1px;
                                left: 0;
                                visibility: hidden;
                                font-size: 14px;
                                text-align: center;
                                line-height: 20px;
                            }
                        }
                        &:checked {
                            & + span {
                                &:before {
                                    background: transparent;
                                }
                                &:after {
                                    visibility: visible;
                                }
                            }
                        }
                    }
                    .wpcf7-list-item-label {
                        margin-left: 33px;
                        display: inline-block;
                    }
                }
            }
        }

        .item {
            margin: rem(30) 0;

            p {
                line-height: 1.5;
            }
        }
        
        // types
        label {
            display: block;
            font-weight: 600;
            margin-bottom: rem(15);
        }
        .input {
            width: 100%;
            display: block;
            padding: rem(12) rem(9);
            appearance: none;
            outline: none;
            font-family: $font;
            background-color: #e5eff7;
            color: $blue-dark;
        }

        textarea,
        .textarea {
            font-family: $font;
            //height: 160px;
            appearance: none;
            outline: none;
            background-color: #e5eff7;
        }

        .wpcf7-form-control-wrap {
            width: 100%;
        }

        .sent {
            .wpcf7-response-output {
                background-color: $green-form;
                color: $white;
                padding: rem(10) rem(20);
            }
        }
        .wpcf7-not-valid-tip {
            color: $red-form-dark;
            font-size: rem(14);
            padding-top: rem(10);
        }

    }

    .wpcf7-response-output {
        margin: rem(50) auto !important;
        padding: rem(20) !important;
        border: 0 !important;
        font-size: rem(17);
        background-color: $red-form;
        color: $red-form-dark;
        text-align: center;
    }
    .sent {
        .wpcf7-response-output {
            background-color: $green-form;
            color: $green-form-light;
        }
    }

    .form__footer {
        text-align: center;
        
        .wrap {
            position: relative;
            display: inline-block;
        }
        .wpcf7-spinner {
            position: absolute;
            top: 8px;
            right: -60px;
        }
    }
}

.whistleblowing-response {
    background-color: $blue-light;
    color: $white;
    padding: rem(70) rem(110);
    line-height: 1.5;
    text-align: center;
    margin-top: rem(50);

    h1, h2, h3 {
        color: $white;
    }
    p {
        margin-bottom: rem(20);
    }
    & > :last-child {
        margin-bottom: 0 !important;
    }
}