.button {
    background-color: $blue-dark;
    color: $white;
    font-weight: 700;
    font-size: initial;
    padding: rem(9) rem(23);
    transition: background-color .3s ease;
    color: $white;
    border: solid 1px rgba(226, 226, 226, .4);
    border-radius: 5px;
    text-decoration: none;
    margin-top: rem(10);

    &:after {
        content: url("../img/icons/chevron-right.svg");
        position: relative;
        top: 3px;
        margin-left: rem(10);
    }

    &:hover {
        text-decoration: none;
        background-color: #0b528c;
        color: $white;
    }
}

// .button--header {
//     background-color: transparent;
//     &:hover {
//         background-color: $blue;
//     }
// }