.homepage {
  
  // HERO
  .hero {
    //padding-top: rem(180);
    background: url("../img/backgrounds/hero-bg.jpg") center no-repeat;
    background-size: cover;
    color: $white;
    height: 100vh;
    min-height: 870px;
    overflow: hidden;

    @include query-to(1300) {
      height: auto;
    }

    .container {
      max-width: 1960px;
      height: 100%;
      display: flex;
      align-items: center;

      @include query-to(1960) {
        max-width: 1570px;
      }

      .inner {
        display: flex;
        justify-content: space-between;
        width: 100%;

        @include query-to(1300) {
          flex-direction: column;
        }

        .left {
          max-width: 846px;
          width: 100%;
          padding: rem(230) 0 rem(140) rem(206);

          @include query-to(1960) {
            padding: rem(230) 0 rem(140) 0;
          }

          @include query-to(1300) {
            padding: rem(230) 0 rem(120) 0;
            max-width: 640px;
            width: 100%;
            margin: 0 auto;
            text-align: center;
          }

          @include query-to(1240) {
            padding: rem(110) 0 rem(60);
          }

          img {
            max-width: 430px;
            width: 100%;
            margin-bottom: rem(30);
          }

          h1 {
            color: $white;
            margin-bottom: rem(30);
          }
          p {
            margin-bottom: rem(20);
            line-height: 1.5;
          }
          & > :last-child {
            margin-bottom: 0;
          }
        }
        .right {
          display: flex;
          align-items: center;
          justify-content: center;

          @include query-to(1960) {
            padding-left: rem(60);
            position: relative;
            width: 100%;
            left: 10%;

            img {
              position: absolute;
              min-width: 900px;
            }
          }
          @include query-to(1300) {
            padding-left: 0;
            left: 0;
            padding-bottom: rem(140);

            img {
              position: initial;
              left: initial;
            }
          }
          @include query-to(1240) {
            padding-bottom: rem(60);
          }
        }
      }
    }
  }

  // CERTIFICATES
  .certificates {
    padding: rem(140) rem(20);
    text-align: center;
    max-width: 1678px;
    margin: 0 auto;

    @include query-to(1240) {
      padding: rem(60) rem(20);
    }

    h2 {
      margin-bottom: rem(50);
    }
    .grid {
      position: relative;
      height: 600px;

      @include query-to(1410) {
        height: auto;
      }

      .row {
        display: flex;
        flex-wrap: wrap;
        position: absolute;

        @include query-to(1410) {
          position: initial;
          justify-content: center;
          flex-wrap: initial;
        }
        @include query-to(840) {
          flex-direction: column;
          align-items: center;
        }

        &:nth-child(1) {
          top: 0;
          left: calc(326px - 54px);
        }

        &:nth-child(2) {
          top: 155px;

          @include query-to(1680) {
            .grid__item:nth-child(3) {
              display: none;
            }
          }
          @include query-to(1410) {
            .grid__item:nth-child(1) {
              display: none;
            }
            .grid__item:nth-child(2) {
              margin-bottom: 0;
            }
          }
          
        }
        &:nth-child(3) {
          top: 315px;
          left: calc(326px - 54px);

          @include query-to(1410) {
            display: none;
          }
        }

        .grid__item {

          margin: 0 rem(110);

          @include query-to(1410) {
            margin: 0 rem(30) rem(20);
          }

          .hexagon {
            display: flex;
            position: relative;
            height: 100%;

            .part {
              transition: border-color .3s ease, background-color .3s ease;
            }

            .part:first-child {
              float: left;
              border-right: 80px solid #f8f8f8;
              border-top: 143px solid transparent;
              border-bottom: 143px solid transparent;
            }
            .part:nth-child(2) {
              float: left;
              width: 166px;
              height: 286px;
              background-color: #f8f8f8;
            }
            .part:nth-child(3) {
              float: left;
              border-left: 80px solid #f8f8f8;
              border-top: 143px solid transparent;
              border-bottom: 143px solid transparent;
            }
            .overlay {
              text-decoration: none;
              position: absolute;
              top: 0;
              right: 0;
              left: 0;
              bottom: 0;
              width: 100%;
              height: 100%;
              display: flex;
              align-items: center;
              justify-content: center;
              flex-direction: column;
              z-index: 1;

              .icon {
                margin-bottom: rem(30);
                svg g {
                  transition: fill .3s ease;
                }
              }
              .text {
                font-weight: 700;
                font-size: rem(20);
                color: $blue;
                transition: color .3s ease;
              }
            }

            &.hexagon--link {
              &:hover {
                .part:first-child {
                  border-right-color: $blue-light;
                }
                .part:nth-child(2) {
                  background-color: $blue-light;
                }
                .part:nth-child(3) {
                  border-left-color: $blue-light;
                }
                .overlay {
                  .icon {
                    svg g {
                      fill: $white;
                    }
                  }
                  .text {
                    color: $white;
                  }
                }
              }
            }

          }
        }
      }
    }
  }

  .enviroment {
    padding-bottom: rem(140);

    @include query-to(1240) {
      padding-bottom: rem(60);
    }

    .container {
      .inner {
        max-width: 760px;
        width: 100%;
        margin: 0 auto;
        text-align: center;

        h2 {
          margin-bottom: rem(40);
        }
        p {
          padding-bottom: rem(30);
        }
        ol {
          margin-bottom: rem(50);
          padding-left: rem(20);

          li {
            //text-align: center;
            font-size: rem(20);
            font-weight: 700;
          }
        }
      }
    }
  }

  .map {
    display: flex;
    position: relative;

    @include query-to(1140) {
      overflow: hidden;
      width: 100%;
      padding-top: 54%;
    }
    
    iframe {
      border: none;

      @include query-to(1140) {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        width: 100%;
        height: 100%;
      }
    }
  }

}