.header {
   position: fixed;
   top: 0;
   left: 0;
   right: 0;
   z-index: 999;
   width: 100%;
   height: auto;
   margin: 0 auto;
   transition: all .6s;

   @include query-to(1240) {
      display: flex;
      justify-content: center;
      align-items: center;
   }

   .top {
      padding: rem(30) 0;

      @include query-to(1240) {
         padding: rem(20) 0;
      }

      .container {
         display: flex;
         align-items: center;
         justify-content: space-between;
      }

      .top__right {
         display: flex;
         align-items: center;

         @include query-to(1240) {
            display: none;
         }

         ul:first-child {
            list-style: none;
            display: flex;

            li {
               display: inline-block;

               &:first-child {
                  margin-right: rem(30);
               }

               a {
                  color: $white;
                  font-weight: 600;
                  display: flex;

                  &.phone {
                     &:before {
                        content: "";
                        width: 20px;
                        height: 20px;
                        display: inline-block;
                        background: url("../img/icons/phone.svg") center no-repeat;
                        margin-right: rem(10);
                     }
                  }
                  &.mail {
                     &:before {
                        content: "";
                        width: 20px;
                        height: 20px;
                        display: inline-block;
                        background: url("../img/icons/mail.svg") center no-repeat;
                        margin-right: rem(10);
                     }
                  }
               }
            }
         }

         ul.langs {
            list-style: none;
            display: flex;
            margin-left: rem(30);

            li {
               display: inline-block;
               color: $white;
               font-weight: 600;
               padding: 0 rem(2);

               a {
                  //font-weight: 600;
               }
            }
         }

         .button {
            margin-top: 0;
            margin-left: rem(80);
         }
      }
   }

   .logo {
      font-style: italic;
      font-size: rem(18);
      font-weight: 700;
      text-decoration: none;
      color: $white;
      
      @include query-to(1240) {
         font-size: rem(14);
      }
   }

   .navbar {
      padding: rem(15) 0;
      border-top: solid 1px rgba(226, 226, 226, .4);
      border-bottom: solid 1px rgba(226, 226, 226, .4);

      @include query-to(1240) {
         display: none;
      }

      .menu {
         top: 0;
         width: auto;
         height: auto;
         padding: 0rem;
         background: none;
         box-shadow: none;
         margin: 0 auto;
         left: 0;
         transition: all 0.4s ease-in-out;
         display: flex;
         align-items: center;
         justify-content: flex-end;

         &-inner {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            column-gap: rem(25);
            //margin: 0 auto;
            list-style: none;

            .menu-item {
               & > a {
                 position: relative; 
               }

               &:first-child {
                  & > a {
                     padding-left: 0;
                  }
               }

               &:hover,
               &.menu-item--active,
               &.current_page_parent,
               &.current-page-ancestor {
                 & > a {
                   text-decoration: underline;
                 }
               }
               &.menu-item-object-custom {
                  a {
                     text-decoration: none;
                     
                     &:hover {
                        text-decoration: underline;
                     }
                  }
               }
            }
         }

         &-link {
            font-family: inherit;
            font-style: normal;
            text-align: center;
            padding: rem(10);
            font-size: rem(15);
            font-weight: 600;
            color: $white;
            text-decoration: none;
            line-height: 1.4;
         }

      }

   }

   // mobile menu trigger
   .menu-trigger {
      display: none;
      cursor: pointer;
      z-index: 99999999999999999;
      transition: all 0.5s;
      padding-right: 5px;
      position: absolute;
      right: 20px;

      span {
         display: inline-block;
         width: 25px;
         height: 2px;
         background-color: $white;
         margin: 2.5px 0;
         transition: all 0.5s;
      }

      &.trigger--active {
         
          span {
             background-color: $white !important;
             &:first-child {
               transform: rotateZ(45deg) scaleX(1.25) translate(4px, 0);
            }
            &:last-child {
               transform: rotateZ(-45deg) scaleX(1.25) translate(4px, 0);
            }
         }
      }
      @include query-to(1240) {
         display: flex;
         flex-direction: column;
      }
   }

   // }

   &.header--changed {
      transform: translate3d(0, -100%, 0);

      @include query-to(1240) {
         transform: none;
      }
   }

   &.on-scroll,
   &.header--hover,
   &.header--blue {
      //background-color: $blue-light;
      background: rgb(3,100,179);
      background: linear-gradient(180deg, rgba(3,100,179,1) 0%, rgba(14,107,182,1) 20%, rgba(41,171,229,1) 100%);
   }

   @include query-to(1240) {
      justify-content: space-between;
   }
}

// mobile navigation
.mobile-navigation {
   display: none;
   justify-content: space-between;
   flex-direction: column;
   height: 100%;
   width: 320px;
   position: fixed;
   z-index: 999999999;
   top: 0;
   right: -320px;
   background-color: $blue;
   overflow-x: hidden;
   transition: 0.5s;
   padding-top: 90px;

   @include query-to(1240) {
      display: flex;
   }

   .wrapper {
      ul {
         list-style: none;
      }
      ul li {
         .submenu {
            padding-left: rem(30);
            list-style: none;
         }
         &.active a {
            opacity: 1;
            font-weight: bold;
         }
      }
      ul li a,
      ul li span {
         margin: rem(10) rem(30);
         text-decoration: none;
         color: $white;
         display: inline-block;
         transition: 0.3s;
         opacity: 1;
         max-width: 180px;
         width: 100%;
      }
   }
   &.mobile-navigation--active {
      right: 0;
   }
   .mobile-navigation__footer {
      padding: rem(30);
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      color: $white;

      ul.langs {
         list-style: none;
         display: flex;

         li {
            display: inline-block;
            color: $white;
            font-weight: 600;
            padding: 0 rem(2);

            a {
               //font-weight: 600;
            }
         }
      }

      a {
         margin-bottom: rem(10);
         font-weight: 600;
         display: flex;
         text-decoration: none;

         &.phone {
            &:before {
               content: "";
               width: 20px;
               height: 20px;
               display: inline-block;
               background: url("../img/icons/phone.svg") center no-repeat;
               margin-right: rem(10);
            }
         }
         &.mail {
            &:before {
               content: "";
               width: 20px;
               height: 20px;
               display: inline-block;
               background: url("../img/icons/mail.svg") center no-repeat;
               margin-right: rem(10);
            }
         }
      }
      &:last-child {
         margin-bottom: 0;
      }
      
   }
}
